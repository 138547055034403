<template>

  <!-- Table Container Card -->
  <div>

    <b-row>
      <b-col
        v-if="true"
        cols="3"
        md="4"
        sm="12"
      >
        <statistic-card-horizontal
          icon="HashIcon"
          color="warning"
          :statistic="total_rows"
          statistic-title="Profiles Shared"
        />
      </b-col>
      <b-col
        v-if="false"
        cols="3"
        md="4"
        sm="12"
      >
        <card-statistic-bar-chart
          :data="staff"
          statistic-title="Staff"
        />
      </b-col>
      <b-col
        v-if="false"
        cols="3"
        md="4"
        sm="12"
      >
        <card-statistic-bar-chart
          :data="actions"
          statistic-title="Actions"
        />
      </b-col>
      <b-col
        cols="3"
        md="4"
        sm="12"
      >
        <card-statistic-bar-chart
          :data="stage"
          statistic-title="Stage"
        />
      </b-col>
    </b-row>

    <b-card
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->

        <b-row>

          <!-- Per Page -->
          <b-col
            v-if="isAdmin"
            cols="6"
            md="4"
            sm="6"
          >
            <small>Partner</small>
            <v-select
              v-model="Selected.partner"
              :options="catalogLists.partner"
              label="text"
              class="invoice-filter-select"
              placeholder="Select partner"
              :clearable="true"
            />
          </b-col>
          <!-- Per Page -->
          <b-col
            v-if="isAdmin || isManager"
            cols="6"
            md="4"
            sm="6"
          >
            <small>Staff</small>
            <v-select
              v-model="Selected.staff"
              :options="catalogLists.staff"
              label="text"
              class="invoice-filter-select"
              placeholder="Select staff"
              :clearable="true"
            />
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="4"
          >
            <small>Stage</small>
            <v-select
              v-model="Selected.stage"
              :options="catalogLists.stage"
              label="text"
              class="invoice-filter-select"
              placeholder="Select stage"
              :clearable="true"
            />
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="6"
            md="4"
            sm="6"
          >
            <small>Action</small>
            <v-select
              v-model="Selected.actions"
              :options="catalogLists.actions"
              label="text"
              class="invoice-filter-select"
              placeholder="Select action"
              :clearable="true"
            />
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="6"
            md="4"
            sm="6"
          >
            <small>Shared Date</small>
            <flat-pickr
              v-model="shared_date_range"
              class="form-control"
              :config="{ mode: 'range'}"
            />
          </b-col>
          <!-- Per Page -->
          <!-- <b-col
            cols="6"
            md="4"
            sm="6"
          >
            <small>Agent</small>
            <v-select
              v-model="Selected.agent"
              :options="catalogLists.agent"
              label="text"
              class="invoice-filter-select"
              placeholder="Select Agent"
              :clearable="true"
              return-object
            />
          </b-col> -->
          <!-- Per Page -->
          <b-col
            cols="6"
            md="4"
            sm="6"
          >
            <b-button
              block
              variant="primary"
              class="mt-2"
              @click="updateSelect()"
            >
              Filter
            </b-button>
          </b-col>
          <b-col
            v-if="!isStaff"
            cols="6"
            md="4"
            sm="6"
          >
            <b-button
              block
              variant="success"
              class="mt-2"
              @click="getSharedPatientsExcel()"
            >
              Export
            </b-button>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="tableTickets"
        :items="items"
        :fields="filterFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        responsive
        style="position: relative !important;"
        @sort-changed="onSortChange"
      >
        <!-- Column: Patient -->
        <template #cell(profile)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: DOB -->
        <!-- <template #cell(dob)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

        <!-- Column: Phone -->
        <template #cell(phone)="data">
          <span class="text-nowrap">
            {{ data.value | phone-format }}
          </span>
        </template>

        <!-- Column: Agent -->
        <template #cell(agent)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Partner -->
        <template #cell(partner)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Staff -->
        <template #cell(staff)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Stage -->
        <template #cell(action_outcome)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Stage -->
        <template #cell(stage)="data">
          <span class="text-center">
            {{ data.value }} <br>
            <small
              v-if="data.value.trim() === 'Scheduled'"
            >
              <b> {{ data.item.schedule_time }} </b>
            </small>
          </span>
        </template>

        <!-- Column: Shared Date -->
        <template #cell(shared_date)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: status -->
        <template
          v-show="isAdmin"
          #cell(status)="data"
        >
          <span
            v-show="isAdmin"
            class="text-nowrap"
          >
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(Actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`preview-row-${data.item.id}-actions-icon`"
              icon="PhoneCallIcon"
              size="16"
              class="cursor-pointer"
              @click="setAction(data.item)"
            />
            <b-tooltip
              title="Action"
              class="no-pointer-event"
              :target="`preview-row-${data.item.id}-actions-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-feedback-icon`"
              icon="FilePlusIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click="viewFeedback(data.item)"
            />
            <b-tooltip
              title="Notes"
              class="no-pointer-event"
              :target="`invoice-row-${data.item.id}-feedback-icon`"
            />

          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ FullCountEntries > 0 ? perPage : 0 }} of {{ FullCountEntries }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :current-page="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <!-- <sidebar-add-new-ticket
      :new-ticket="ticket"
      :refresh-handler="getTickets"
    /> -->
      <patient-action-modal
        :refresh-handler="getSharedPatients"
      />
      <patient-feedback-modal />
      <patient-log-modal />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BPagination, BTable, VBToggle, BTooltip, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import shareService from '@core/services/share/shareService'
import flatPickr from 'vue-flatpickr-component'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import CardStatisticBarChart from '@core/components/modals/lists/CardStatisticBarChart.vue'
import PatientActionModal from '@/@core/components/modals/followup/PatientActionModal.vue'
import PatientFeedbackModal from '@/@core/components/modals/followup/PatientFeedbackModal.vue'
import PatientLogModal from '@/@core/components/modals/followup/PatientLogModal.vue'
// import Swal from 'sweetalert2'
// import SidebarAddNewTicket from './sidebar/SidebarAddNewTicket.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BPagination,
    vSelect,
    BTable,
    BTooltip,
    // SidebarAddNewTicket,
    PatientActionModal,
    PatientFeedbackModal,
    PatientLogModal,
    flatPickr,
    StatisticCardHorizontal,
    CardStatisticBarChart,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      fields: [
        {
          key: 'profile', label: 'Name', sortable: true, showStaff: true,
        },
        {
          key: 'phone', label: 'Phone', sortable: true, showStaff: true,
        },
        {
          key: 'agent', label: 'Agent', sortable: true, showStaff: true,
        },
        // {
        //   key: 'partner', label: 'Partner', sortable: true, showStaff: true,
        // },
        {
          key: 'trips', label: 'Trips', sortable: true, showStaff: true,
        },
        {
          key: 'amount', label: 'Amount', sortable: true, showStaff: true,
        },
        {
          key: 'staff', label: 'Staff', sortable: true, showStaff: true,
        },
        {
          key: 'stage', label: 'Stage', sortable: true, showStaff: true,
        },
        {
          key: 'shared_date', label: 'Shared Date', sortable: true, showStaff: true,
        },
        {
          key: 'action_outcome', label: 'Last Call Action', sortable: true, showStaff: true,
        },
        {
          key: 'status', label: 'Status', sortable: true, showStaff: false,
        },
        { key: 'Actions', sortable: false, showStaff: true },
      ],
      items: [],
      TypeofStaff: [],
      type: '',
      perPage: 25,
      PageOptions: [10, 25, 50, 100],
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'Id',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      avatarText,
      ticket: {},
      permission: true,
      partner_id: 0,
      partner_staff_id: 0,
      stage_id: 0,
      action_id: 0,
      shared_date_range: null,
      agent_id: 0,
      catalogLists: {
        actions: [],
        stage: [],
        agent: [],
        staff: [],
        partner: [],
      },
      Selected: {
        actions: null,
        stage: null,
        agent: null,
        staff: null,
        partner: null,
      },
      staff: { labels: null, totals: null },
      stage: { labels: null, totals: null },
      actions: { labels: null, totals: null },
      total_rows: 0,
    }
  },
  computed: {
    isAdmin() {
      const user = this.$store.getters['user-auth/getUser']
      return user.id_profile === 1
    },
    isManager() {
      const user = this.$store.getters['user-auth/getUser']
      return user.id_profile === 2
    },
    filterFields() {
      const user = this.$store.getters['user-auth/getUser']
      const subdomain = window.location.host.split('.')[0]
      let { fields } = this
      if (subdomain !== 'uber') fields = fields.filter(field => !['trips', 'amount'].includes(field.key))
      return user.id_partner === 0 ? fields : fields.filter(field => field.showStaff)
    },
    isStaff() {
      const user = this.$store.getters['user-auth/getUser']
      return user.id_profile === 3
    },
  },
  watch: {
    perPage() {
      this.getSharedPatients()
    },
  },
  mounted() {
    this.getAllStaffs()
    this.getCatalogs()
    setTimeout(() => {
      const user = this.$store.getters['user-auth/getUser']
      // Find the corresponding object in catalogLists.agent
      // const selectedAgent = this.catalogLists.agent.find(agent => agent.value === user.id_caretrack_user)
      const selectedPartner = this.catalogLists.partner.find(partner => partner.value === user.id_partner)
      const selectedStaff = this.catalogLists.staff.find(staff => staff.value === user.id_partners_staff)
      // Set the found object as the value of Selected.agent
      // this.Selected.agent = selectedAgent
      // console.log(user)
      switch (user.id_profile) {
        case 1: // admin
          this.partner_id = user.id_partner
          this.Selected.partner = selectedPartner
          this.partner_staff_id = 0
          break
        case 2: // MANAGER
          this.partner_id = user.id_partner
          this.Selected.partner = selectedPartner
          this.partner_staff_id = 0
          break
        case 3: // staff
          this.partner_id = user.id_partner
          this.partner_staff_id = user.id_partners_staff
          this.Selected.partner = selectedPartner
          this.Selected.staff = selectedStaff
          break
        default:
          break
      }
    }, 500)
    setTimeout(() => {
      this.getSharedPatients()
    }, 1000)
  },
  methods: {
    updateSelect() {
      this.getSharedPatients()
    },
    async getCatalogs() {
      const listCatalogs = Object.keys(this.catalogLists)
      listCatalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    async getSpecificCatalog(catalog) {
      return shareService.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const { data } = response.data
        this.catalogLists[catalog] = data
      }).catch(error => {
        this.toastErrorHandler(error)
      })
    },
    viewFeedback(item) {
      this.$bus.$emit('modal-feedback', { item })
      this.$root.$emit('bv::show::modal', 'modal-feedback')
    },
    setAction(item) {
      this.$bus.$emit('modal-action', { item })
      this.$root.$emit('bv::show::modal', 'modal-action')
    },
    viewLog(item) {
      this.$bus.$emit('modal-log', { item })
      this.$root.$emit('bv::show::modal', 'modal-log')
    },
    getAllStaffs() {
      const profile = this.$store.getters['user-auth/getStaffProfile']
      if (profile === 2) {
        this.fields.pop()
        this.permission = false
      }
    },
    getSharedPatients() {
      let beginDate = ''
      let endDate = ''
      if (this.shared_date_range && this.shared_date_range.includes('to')) {
        const [_beginDate, _endDate] = this.shared_date_range.split(' to ')
        beginDate = _beginDate
        endDate = _endDate
      }

      shareService.getSharedProfiles({
        page: this.actualpage,
        rows: this.perPage,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
        partner_id: this.Selected.partner ? this.Selected.partner.value : this.partner_id,
        partner_staff_id: this.Selected.staff ? this.Selected.staff.value : this.partner_staff_id,
        stage_id: this.Selected.stage ? this.Selected.stage.value : 0,
        action_id: this.Selected.actions ? this.Selected.action.value : 0,
        shared_date_begin: beginDate,
        shared_date_end: endDate,
        agent_id: this.Selected.agent ? this.Selected.agent.value : 0,
      }).then(response => {
        const { data } = response.data

        this.items = data
        this.FullCountEntries = !data ? 0 : data[0].full_count
        this.numPages = !data ? 0 : Number(data[0].pages)
        console.log(this.FullCountEntries)
        this.totalRows = this.FullCountEntries
        this.currentPage = this.actualpage
      })
      this.getSharedPatientsCharts()
    },
    getSharedPatientsCharts() {
      const user = this.$store.getters['user-auth/getUser']
      let beginDate = ''
      let endDate = ''
      if (this.shared_date_range && this.shared_date_range.includes('to')) {
        const [_beginDate, _endDate] = this.shared_date_range.split(' to ')
        beginDate = _beginDate
        endDate = _endDate
      }
      const params = {
        profile_id: user.id_profile,
        partner_id: this.Selected.partner ? this.Selected.partner.value : this.partner_id,
        partner_staff_id: this.Selected.staff ? this.Selected.staff.value : this.partner_staff_id,
        shared_date_begin: beginDate,
        shared_date_end: endDate,
      }
      shareService.getSharedProfilesChartStaff(params).then(response => {
        const { data } = response.data
        this.staff = this.processObjectToChart(data)
        /* eslint-disable prefer-destructuring */
        const sum = this.staff.totals.reduce((total, item) => total + item, 0)
        this.total_rows = sum
      })
      shareService.getSharedProfilesChartStages(params).then(response => {
        const { data } = response.data
        this.stage = this.processObjectToChart(data)
      })
      shareService.getSharedProfilesChartActions(params).then(response => {
        const { data } = response.data
        this.actions = this.processObjectToChart(data)
      })
    },
    processObjectToChart(data) {
      const labels = data.map(obj => obj.value)
      const totals = data.map(obj => obj.total)
      return { labels, totals }
    },
    getSharedPatientsExcel() {
      let beginDate = ''
      let endDate = ''
      if (this.shared_date_range && this.shared_date_range.includes('to')) {
        const [_beginDate, _endDate] = this.shared_date_range.split(' to ')
        beginDate = _beginDate
        endDate = _endDate
      }
      shareService.getSharedProfilesExcel({
        page: this.actualpage,
        rows: this.perPage,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
        partner_id: this.Selected.partner ? this.Selected.partner.value : this.partner_id,
        partner_staff_id: this.Selected.staff ? this.Selected.staff.value : this.partner_staff_id,
        stage_id: this.Selected.stage ? this.Selected.stage.value : 0,
        action_id: this.Selected.actions ? this.Selected.action.value : 0,
        shared_date_begin: beginDate,
        shared_date_end: endDate,
        agent_id: this.Selected.agent ? this.Selected.agent.value : 0,
      }).then(response => {
        // Create a new Blob object from the response data
        // const date = new Date()
        // const formattedDate = this.formatDate(date)
        const blob = response.data

        // Create a new Blob object
        const newBlob = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        // Create a new anchor element
        const anchor = document.createElement('a')

        // Set the href attribute to the new Blob object
        anchor.href = URL.createObjectURL(newBlob)

        // Set the download attribute
        anchor.download = 'Shared & Connect.xlsx'

        // Click the anchor element to download the file
        anchor.click()

        // Revoke the object URL
        URL.revokeObjectURL(anchor.href)
      })
    },
    formatDate(date) {
      const month = date.getMonth() + 1
      const day = date.getDate()
      const year = date.getFullYear()

      const formattedMonth = month < 10 ? `0${month}` : month
      const formattedDay = day < 10 ? `0${day}` : day

      return `${formattedMonth}/${formattedDay}/${year}`
    },
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getSharedPatients()
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getSharedPatients()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getSharedPatients()
    },
  },
}
</script>

<style>
.tooltip{
  pointer-events: none !important;
}

.flatpickr-calendar .flatpickr-day.inRange, .flatpickr-calendar .flatpickr-day.inRange:hover{
  color: white;
}

.per-page-selector {
  width: 90px;
}
.vs__selected-options{
    height: 30px !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
